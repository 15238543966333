<template>
	<div id="sign-in">
		<div class="modal">
			<form class="login-form" @submit.prevent="loginWithEmailAndPassword">
				<fieldset>
					<div>
						<label class="label" for="email">User name</label>
						<input type="email" name="email" id="email" v-model="email" required>
					</div>
					<div>
						<label class="label" for="textarea">Password</label>
						<input type="password" name="password" id="password" v-model="password" required
						@keyup.enter="login">
					</div>
					<div>
						<button class="signin-button shadow">Sign in</button>
					</div>

				</fieldset>
			</form>
		</div>

	</div>
</template>

<script>
// import {GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword} from 'firebase/auth'
import {signInWithEmailAndPassword} from 'firebase/auth'
import {firebaseAuth} from '../firebase'
export default {
	data() {
		return {
			email: null,
			password: null
		}
	},
	props: [
		"loggedIn",
		"auth"
	],
	created(){
		this.$router.push('/')
	},
	mounted(){
    // onAuthStateChanged(this.auth, (user) => {
    //   if (user) {
    //     this.$router.push('/')
    //   }
    // });
	},
	methods: {
		// signIn() {
		// 	const provider = new GoogleAuthProvider()

		// 	return signInWithPopup(firebaseAuth, provider)
		// 	.then(() => this.$router.replace({
		// 		name: 'Account'
		// 	}))
		// 	.catch(error => {
		// 		switch(error.code){
		// 			case 'auth/user-not-found':
		// 			alert("User not found")
		// 			break
		// 			case 'auth/wrong-password':
		// 			alert("Wrong password")
		// 			break
		// 			default:
		// 			alert("Something went wrong :(")
		// 		}
		// 	})
		// },
		loginWithEmailAndPassword() {
			return signInWithEmailAndPassword(firebaseAuth, this.email, this.password)
			.then(() =>
			this.$router.push('/')
			)
			.catch(error => {
				switch(error.code){
					case 'auth/user-not-found':
					alert("User not found")
					break
					case 'auth/wrong-password':
					alert("Wrong password")
					break
					default:
					alert("Something went wrong :(")
				}
			})
		},
	}
}
</script>

<style lang="scss">
@import '../scss/global.scss';
#sign-in {
	@include for-phone-only(){
    padding: 1em 0;
	}
	display: flex;
	padding: 3em 0;
	align-items: center;
	justify-content: center;
	height: 100vh;
	img{
		position: absolute;
		height: 100vh;
	}
}
.modal{
	@include for-phone-only(){
		width: 80%;
		padding: 15px 1.5em;
	}
	width: 40%;
	padding: 15px 5em;
	margin: auto;
	background-color: #00000044;
	border-radius: 3em;
	box-shadow: 1px 1px 58px 11px rgba(0,0,0,5%);
	-webkit-box-shadow: 1px 1px 58px 11px rgba(0,0,0,5%);
	-moz-box-shadow: 1px 1px 58px 11px rgba(0,0,0,5%);
  backdrop-filter: blur(10px);
	#googleLogin{
		@include for-phone-only(){
			margin: 20px auto;
		}
		padding: 5px 20px 5px 7px;
		svg{
			vertical-align: middle;
			margin: 0 1em 0 0 ;
			background: $white;
			padding: 8px;
			border-radius: 20px;
		}
	}
	.login-form, .register-form, .password-reset-form {
		@include for-phone-only(){
			margin: 20px auto;
		}
		margin: 50px auto;
		fieldset {
			border: none;
		}
		div {
			position: relative;
			margin: 20px 0;
		}
		.label {
			color: #ffffff;
			margin-bottom: 10px;
			display: inline-block;
			line-height: 2rem;
			width: 50%;
		}
		input{
			color: #2c3e50;
			display: block;
			width: 100%;
			appearance: none;
			padding: 10px 3%;
			border: 1px solid #cfd9db;
			border-radius: 20px;
			background-color: #ffffff;
		}
		input[type="text"]:focus,
		input[type="email"]:focus,
		input[type="password"]:focus{
			outline: none;
			border-color: $red;
			box-shadow: 0px 5px 15px 3px rgba(0,0,0,0.15);
		}
		.signin-button{
			font-family: 'Artegra', helvetica, sans-serif;
			font-weight: 400;
			width: 30%;
			padding: 1em;
      border-radius: 30px;
      border: none;
			cursor: pointer;
		}
		#resetPassword{
			@include for-phone-only(){
				margin: 0.5em 0 0 0;
				padding: 3px 30px;
			}
			display: inline-block;
      color: #2c3e50;
      padding: 3px 15px;
			text-align: right;
      margin: 0 0 0 2em;
      border: solid 1px #2A3854;
      border-radius: 25px;
		}
		#register{
			@include for-phone-only(){
				margin: 0.5em 0 0 0;
				padding: 3px 30px;
			}
			display: inline-block;
      color: #2c3e50;
      padding: 3px 15px;
			text-align: right;
			margin: 0 0 0 2em;
      border: solid 1px #2A3854;
      border-radius: 25px;
		}
	}
}
</style>
