<template>
  <div id="footer" ref="footer">
    <div id="footBox">

      <div id="footLogo" ref="logo">
        <!-- <router-link to="/" title="Lighthouse Games">
          <img src="../assets/imgs/Logo-mark.svg" alt="">
        </router-link> -->
      </div>

      <div id="footSocial">
        <a class="socialIcon" href="https://www.linkedin.com/company/lighthousegames" target="_blank">
          <img src="../assets/imgs/linkedIn.svg" height="40" alt="linkedIn-icon">
        </a>
        <a class="socialIcon" href="https://twitter.com/LighthouseLHG" target="_blank">
          <img src="../assets/imgs/twitter.svg" height="40" alt="twitter-icon">
        </a>
      </div>

      <div id="footLinks">
        <router-link to="/contact">Get in touch&nbsp;&nbsp;▸</router-link>
        <router-link to="/terms-of-use">Terms of use&nbsp;&nbsp;▸ </router-link>
        <router-link to="/privacy">Privacy policy&nbsp;&nbsp;▸ </router-link>
      </div>

      <div class="copyrights">© Lighthouse Games 2023. All Rights Reserved</div>

    </div>



  </div>
</template>

<script>
export default {
  name: "lighthouseHeader",

  methods: {
    logout() {
      this.$emit('logout')
    }
  }
}
</script>

<style lang="scss" scoped>
#footer {
  background-color: #000;
  width: 100%;
  transition: all 0.4s ease;
  z-index: 20;
  text-align: center;
  margin: 0 auto;

  @include for-phone-only() {
    width: 80%;
  }

  @media (max-width: 900px) {
    width: 80%
}

}



#footBox {
  text-align: center;
}

#footLinks {
  // display: flex;
  margin: 0 auto;

  a {
    display: inline-block;
    padding: 2em;

    @media (max-width: 900px) {
    display: block;
    padding: 1em 1em 0 1em;
}


  }
}


#footLogo {
  // padding: 2em;
  margin-bottom: 2em;

  img {
    width: 100px;

    @include for-phone-only() {
      width: 25%;
    }
  }
}

#footSocial {
  display: inline;


  text-align: center;

  // margin-bottom: 2em;
  .socialIcon {
    display: inline;
    padding: 1em;
    text-align: center;
    img{
      width: 30px;
    }
    @include for-phone-only() {
      // padding: 2em;
    }
  }
}

.copyrights {
  padding: 1em;

  @include for-phone-only() {}

  font-size: 0.7em;
}

</style>