<template>
  <div id="modal-container" @click="closeModal" ref="modalContainer">
    <div class="modal">
      <img src="../assets/imgs/x.svg" alt="Close Menu" @click="closeModal" class="close-button" ref="closeBtn">

      <div class="outputWrapper">
        <p>
          {{ this.selectedPerson }}
        </p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'modal',
  data() {
    return {
    }
  },
  props: [
    "selectedPerson"
  ],
  mounted () {
  },
  methods: {
    closeModal (e) {
      if(e.target === this.$refs.modalContainer || e.target == this.$refs.closeBtn || e.target == this.$refs.okBtn)
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped lang="scss">
#modal-container{
  width: 100vw;
  height: 100vh;
  background-color: #00000066;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.modal{
  display: flex;
  flex-direction: column;
  max-width: 36.66%;
  min-height: 40vh;
  max-height: 70vh;
  height: auto;
  border-radius: 0;
  margin: auto;
  position: inherit;
  background: white;
  color: #0d1226;
  padding: 5em 3em 2em 3em;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  .outputWrapper{
      max-height: 100%;
      overflow: scroll;
      mask-image: linear-gradient( to bottom,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 1%, rgba(0,0,0,1) 95%,  rgba(0,0,0,0) 102%);
    p{
      font-size: 0.8em;
      color: #262F6A;
      white-space: pre-wrap;
    }
  }
  .close-button{
    fill: #000000;
    position: absolute;
    top: 10px;
    right: 10px;
    filter: invert(0.7);
    cursor: pointer;
  }
}
button{
  background: #ff0080;
  margin: 1em auto;
}
h5 {
  margin: 40px 0 0;
}
p{
  margin: 0 0 1em 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
