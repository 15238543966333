<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | Lighthouse Games` : `Lighthouse Games` }}</template>
  </metainfo>
  <metainfo>
    <template v-slot:description="{ metainfo }">{{ metainfo.description }}</template>
  </metainfo>
  <vue-cookie-accept-decline
    :debug="false"
    :disableDecline="true"
    :showPostponeButton="false"
    elementId="myPanel1"
    position="bottom-left"
    ref="myPanel1"
    transitionName="slideFromBottom"
    type="floating"
    >
    <!-- Optional -->
    <template #postponeContent>&times;</template>

    <!-- Optional -->
    <template #message>
      We use cookies to ensure you get the best experience on our website.
      <a href="https://cookiesandyou.com/" target="_blank">Learn More...</a>
    </template>

    <!-- Optional -->
    <template #declineContent>Opt Out</template>

    <!-- Optional -->
    <template #acceptContent>Accept</template>
  </vue-cookie-accept-decline>
  <loader ref="loader" v-if="loading"/>
  <LHheader v-if="isLogin" :isMobile="isMobile"/>
  <routerView v-slot="{ Component }" :loading="loading" :loggedIn="loggedIn" :jobs="jobs" :selectedJob="selectedJob" :isMobile="isMobile">
    <transition :name="slide" mode="out-in">
      <component :is="Component" />
    </transition>
  </routerView>
  <LHfooter/>
</template>

<script>
import axios from 'axios';
import {gsap} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LHheader from './components/header.vue';
import LHfooter from './components/footer.vue';
import loader from './components/loader.vue';
// import { getAuth } from "firebase/auth";
import { useMeta } from 'vue-meta'

gsap.registerPlugin(ScrollTrigger);
export default {
  name: 'App',
  setup(){
    useMeta( {
      title: 'Lighthouse Games',
      htmlAttrs: { lang: 'en-GB' },
      meta: [
        { title: 'Lighthouse Games'},
        { charset: 'utf-8' },
        { name: 'description', content: 'Lighthouse works at the bleeding edge of video game development, and our studio attracts and nurtures the best talent in the industry.' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0'},
        { property: 'og:title', content: 'Lighthouse Games'},
        { property: 'og:url', content: 'https://lighthousegames.com/'},
        { property: 'og:description', content: 'Lighthouse works at the bleeding edge of video game development, and our studio attracts and nurtures the best talent in the industry.'},
        { property: 'og:type', content: 'website'},
        { property: 'og:image', content: 'https://lighthousegames.com/img/OG-img.d561af18.jpg'},
        { name: 'robots', content: 'index,follow'},
        { property: 'twitter:card', content: 'https://lighthousegames.com/img/OG-img.d561af18.jpg'},
        { property: 'twitter:site', content: 'https://lighthousegames.com/'},
        { property: 'twitter:title', content: 'Lighthouse Games'},
        { property: 'twitter:description', content: 'Lighthouse works at the bleeding edge of video game development, and our studio attracts and nurtures the best talent in the industry.'},
        { property: 'twitter:image', content: 'https://lighthousegames.com/img/OG-img.d561af18.jpg'},
      ],
      // og: {
      //   url: baseUrl,
      //   type: 'website',
      //   title,
      //   description,
      //   tags,
      //   keywords: tags,
      //   image: baseUrl + require('../src/assets/imgs/OG-img.jpg'),
      // },
    })
  },

  components:{
    LHheader,
    LHfooter,
    loader
  },
  data(){
    return{
      slide: "slideLeft",
      // auth: getAuth(),
      loggedIn: null,
			jobs: null,
      selectedJob: null,
      loading: true
    }
  },
  // watch:{
  //   // eslint-disable-next-line
  //   $route (to, from){
  //     if(to.name != "login" || !this.loggedIn){
  //       onAuthStateChanged(this.auth, (user) => {
  //         if (user != undefined) {
  //           this.loggedIn = true
  //         }else{
  //           this.$router.push('/login')
  //         }
  //       });
  //     }
  //   }
  // },
  created(){
    // onAuthStateChanged(this.auth, (user) => {
    //   if (user != undefined) {
    //     this.loggedIn = true
    //   }else{
    //     this.loggedIn = false
    //     this.$router.push('/login')
    //   }
    // });
    const options = {
      method: 'GET',
      url: 'https://us-central1-lighthouse-games-9b247.cloudfunctions.net/callWorkable',
      headers: {
        accept: 'application/json',
      }
    };
    axios
      .request(options)
      .then((response) => {
        this.jobs = Object.values(response.data.jobs)
        setTimeout(async () => {
          gsap.to(".wrapper", {duration: 0.5, clipPath:"circle(0%)", onComplete: ()=>{
            this.loading = false
          }})
        }, 900);
        // this.getAllDepartments()
      })
      .catch((error) => {
        setTimeout(async () => {
          gsap.to(".wrapper", {duration: 0.5, clipPath:"circle(0%)", onComplete: ()=>{
            this.loading = false
          }})
        }, 900);
        console.error(error);
      });
    },
  mounted(){
    console.log("%c            Created with 🤍 by Diva.agency            ", "font-size: 0.8rem; color: white; line-height: 4rem; background-color:#ff0080");
    this.stickyHeader();
    window.addEventListener("load", () => {

    })
  },
  computed: {
    isMobile() {
      if(navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i) ||
        navigator.userAgent.match(/(iPod|iPhone|iPad)/) !== null && navigator.userAgent.match(/AppleWebKit/) !== null && navigator.userAgent.search('CriOS') < 0 ||
        /(iPhone)*(OS ([7-9]|1[0-9])_)/i.test(navigator.userAgent) ||
        window.matchMedia("only screen and (max-width: 480px)").matches
      ){
        return true;
      } else {
        return false;
      }
    },
    isLogin() {
      if(this.$route.name == 'login'){
        return false
      }else{
        return true
      }
    },
  },
  methods: {
    stickyHeader(){
      if(this.$route.name != 'login'){
        window.addEventListener("scroll", function(){
          if (window.scrollY > 30){
            document.getElementById('header').classList.add("sticky")
            // this.isScrolled = true
          }else{
            document.getElementById('header').classList.remove("sticky")
            // this.isScrolled = false
          }
        })
      }
    },
    // logout(){
    //   signOut(this.auth).then(() => {
    //     this.$router.push('/login');
    //   }).catch((error) => {
    //     console.log(error)
    //   });
    // },
  }

}
</script>

<style lang="scss">
@import './scss/global.scss';

#app {
  font-family: 'Artegra', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  .content{
    @include for-phone-only(){
      padding: 75px 0 5rem 0;
    }
    padding: 150px 0 5rem 0;
  }

  .intro-text{
    @include for-phone-only(){
      width: 90%;
    }
    width: 40em;
    margin: 2em auto;
  }
}
#nav {

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

//Router transitions

.slideLeft-enter-active, .slideLeft-leave-active{
  transition: all .4s ease;
}
// .slideLeft-enter-active{
//     transition-delay: .6s;
// }
.slideLeft-enter-from {
  transform: translateX(5vw);
  opacity: 0;
}
.slideLeft-enter-to, .slideLeft-leave-from{
  transform: translateX(0);
  opacity: 1;
}
.slideLeft-leave-to{
  transform: translateX(-5vw);
  opacity: 0;
}

// Cookie banner styles
.cookie__bar {
  -ms-overflow-style: none;
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 9999;
  width: 100%;
  background: #eee;
  padding: 20px 20px;
  align-items: center;
  box-shadow: 0 -4px 4px rgba(198, 198, 198, 0.05);
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, Arial, sans-serif;
  line-height: 1.5;
}
.cookie__bar--bottom {
  bottom: 0;
  left: 0;
  right: 0;
}
.cookie__bar--top {
  top: 0;
  left: 0;
  right: 0;
}
.cookie__bar__wrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media (min-width: 768px) {
  .cookie__bar__wrap {
    flex-direction: row;
  }
}
.cookie__bar__postpone-button {
  margin-right: auto;
  flex: 1 1 auto;
  -ms-flex: 1 1 auto;
}
@media (min-width: 768px) {
  .cookie__bar__postpone-button {
    margin-right: 10px;
  }
}
.cookie__bar__postpone-button:hover {
  opacity: 0.8;
  cursor: pointer;
}
.cookie__bar__content {
  margin-right: 0;
  margin-bottom: 20px;
  font-size: 0.9rem;
  max-height: 103px;
  overflow: auto;
  width: 100%;
  flex: 1 1 auto;
  -ms-flex: 1 1 auto;
}
@media (min-width: 768px) {
  .cookie__bar__content {
    margin-right: auto;
    margin-bottom: 0;
  }
}
.cookie__bar__buttons {
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 768px) {
  .cookie__bar__buttons {
    flex-direction: row;
    width: auto;
  }
}
.cookie__bar__buttons__button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 3px;
  font-size: 0.9rem;
}
.cookie__bar__buttons__button:hover {
  cursor: pointer;
  text-decoration: none;
}
.cookie__bar__buttons__button--accept {
  flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  background: #4caf50;
  background: linear-gradient(#5cb860, #4caf50);
  color: #fff;
}
.cookie__bar__buttons__button--accept:hover {
  background: #409343;
}
.cookie__bar__buttons__button--decline {
  flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  background: #f44336;
  background: linear-gradient(#f55a4e, #f44336);
  color: #fff;
  margin-bottom: 10px;
}
.cookie__bar__buttons__button--decline:hover {
  background: #f21f0f;
}
@media (min-width: 768px) {
  .cookie__bar__buttons__button--decline {
    margin-bottom: 0;
    margin-right: 10px;
  }
}
.cookie__floating {
  -ms-overflow-style: none;
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 9999;
  width: 90%;
  background: #000000;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(198, 198, 198, 0.3);
  border: 1px solid #ddd;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, Arial, sans-serif;
  line-height: 1.5;
  border-radius: 6px;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .cookie__floating {
    max-width: 300px;
  }
}
@media (min-width: 768px) {
  .cookie__floating--bottom-left {
    bottom: 20px;
    left: 20px;
    right: auto;
    margin: 0 0;
  }
}
@media (min-width: 768px) {
  .cookie__floating--bottom-right {
    bottom: 20px;
    right: 20px;
    left: auto;
    margin: 0 0;
  }
}
@media (min-width: 768px) {
  .cookie__floating--top-right {
    top: 20px;
    bottom: auto;
    right: 20px;
    left: auto;
    margin: 0 0;
  }
}
@media (min-width: 768px) {
  .cookie__floating--top-left {
    top: 20px;
    bottom: auto;
    right: auto;
    left: 20px;
    margin: 0 0;
  }
}
.cookie__floating__postpone-button {
  display: inline-flex;
  padding: 5px 0 0 20px;
  margin-bottom: -10px;
  margin-right: auto;
}
.cookie__floating__postpone-button:hover {
  opacity: 0.8;
  cursor: pointer;
}
.cookie__floating__content {
  font-size: 0.95rem;
  margin-bottom: 5px;
  padding: 15px 20px;
  max-height: 105px;
  overflow: auto;
}
@media (min-width: 768px) {
  .cookie__floating__content {
    margin-bottom: 10px;
  }
}
.cookie__floating__buttons {
  transition: all 0.2s ease;
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
}
.cookie__floating__buttons__button {
  background: #000000;
  font-weight: bold;
  font-size: 0.9rem;
  width: 100%;
  min-height: 40px;
  white-space: nowrap;
  user-select: none;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  border-left: none;
  border-right: none;
  padding: 0.375rem 0.75rem;
}
.cookie__floating__buttons__button:first-child {
  border-right: 1px solid #ddd;
}
.cookie__floating__buttons__button:hover {
  cursor: pointer;
  text-decoration: none;
}
.cookie__floating__buttons__button--accept {
  color: $white;
  flex: 1 1 auto;
  -ms-flex: 1 1 auto;
}
.cookie__floating__buttons__button--accept:hover {
  background: $white;
  color: #fff;
}
.cookie__floating__buttons__button--decline {
  color: #f44336;
  flex: 1 1 auto;
  -ms-flex: 1 1 auto;
}
.cookie__floating__buttons__button--decline:hover {
  background: #f21f0f;
  color: #fff;
}

.slideFromBottom-enter,
.slideFromBottom-leave-to {
  transform: translate(0px, 10em);
}

.slideFromBottom-enter-to,
.slideFromBottom-leave {
  transform: translate(0px, 0px);
}

.slideFromBottom-enter-active {
  transition: transform 0.2s ease-out;
}

.slideFromBottom-leave-active {
  transition: transform 0.2s ease-in;
}

.slideFromTop-enter,
.slideFromTop-leave-to {
  transform: translate(0px, -10em);
}

.slideFromTop-enter-to,
.slideFromTop-leave {
  transform: translate(0px, 0px);
}

.slideFromTop-enter-active {
  transition: transform 0.2s ease-out;
}

.slideFromTop-leave-active {
  transition: transform 0.2s ease-in;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
