<template>
    <div id="not-found">
        <h1>404</h1>
        <p>Page Not Found</p>
        <div id="exploreButton"><a href='/'><button class="exploreBtn">✦ &nbsp; <span class="btnTextShift">Back to Homepage</span></button></a></div>
    </div>
</template>

<style lang="scss">
    #not-found {
        text-align: center;
        // width: 45em;
        margin: 150px auto;

        h1 {
            margin-top: 1em;
            font-size: 9rem;
        }
        h1, h3, h4 {
            font-weight: 600;
        }
        h5, h6 {
            margin: 1em 0;
            font-weight: 400;
            text-decoration: underline;
        }
        p {
            font-family: 'Artegra', helvetica, sans-serif;
            font-weight: 400;
        }
        ul {
            margin: 2em 0;
            padding: 0 0 0 1em;
            li {
                margin: 1em 0;
            }
        }
        #exploreButton {
            button {
                @include for-phone-only {
                    margin-top: 1em;
                }
                font-family: $SansFont;
                font-size: 1.2em;
                font-weight: 700;
                line-height: 1.2em;
                margin-top: 6em;
                padding: .4em 1em;
                background: #000;
                color: $link;
                border: 2px solid;
                border-radius: 3em;
                cursor: pointer;

                &:hover {
                    color: #fff;
                }
            }
        }
    }
</style>