import { createRouter, createWebHistory } from 'vue-router'
import home from '../views/Home.vue'
import login from '../views/Login.vue'
import careers from '../views/Careers.vue'
import privacy from '../views/Privacy.vue'
import termsOfUse from '../views/TermsOfUse.vue'
import job from '../views/JobDetails.vue'
import notFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: home
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (Contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      guestOnly: true,
    }
  },
  {
    path: '/careers',
    name: 'careers',
    component: careers,
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy,
  },
  {
    path: '/terms-of-use',
    name: 'termsOfUse',
    component: termsOfUse,
  },
  {
    path: '/careers/:id',
    name: 'jobDetails',
    component: job,
  },
  {
    path: '/404', name: 'NotFound', component: notFound
  },
  {
      path: '/:catchAll(.*)', redirect:'404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    return { top: 0, left: 0 };
  },
})

export default router
