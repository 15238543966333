<template>
    <div id="privacy">
        <h1>Terms of Use</h1>
        <p>Please read these terms of use ("terms of use", "agreement") carefully before
            using [website] website (“website”, "service") operated by [name] ("us", 'we",
            "our").</p>
        <h3>Conditions of use</h3>
        <p>By using this website, you certify that you have read and reviewed this
            Agreement and that you agree to comply with its terms. If you do not want to be
            bound by the terms of this Agreement, you are advised to leave the website
            accordingly. [name] only grants use and access of this website, its products,
            and its services to those who have accepted its terms.</p>
        <h3>Privacy policy</h3>
        <p>Before you continue using our website, we advise you to read our privacy
            policy [link to privacy policy] regarding our user data collection. It will help
            you better understand our practices.</p>
        <h3>Intellectual property</h3>
        <p>You agree that all materials, products, and services provided on this website
            are the property of [name], its affiliates, directors, officers, employees,
            agents, suppliers, or licensors including all copyrights, trade secrets,
            trademarks, patents, and other intellectual property. You also agree that you
            will not reproduce or redistribute the [name]'s intellectual property in any
            way, including electronic, digital, or new trademark registrations. You grant
            [name] a royalty-free and non-exclusive license to display, use, copy, transmit,
            and broadcast the content you upload and publish. For issues regarding
            intellectual property claims, you should contact the company in order to come to
            an agreement.</p>
        <h3>Applicable law</h3>
        <p>By visiting this website, you agree that the laws of the [location], without
            regard to principles of conflict laws, will govern these terms and conditions,
            or any dispute of any sort that might come between [name] and you, or its
            business partners and associates.</p>
        <h3>Disputes</h3>
        <p>Any dispute related in any way to your visit to this website or to products
            you purchase from us shall be arbitrated by state or federal court [location]
            and you consent to exclusive jurisdiction and venue of such courts.</p>
        <h3>Indemnification</h3>
        <p>You agree to indemnify [name] and its affiliates and hold [name] harmless
            against legal claims and demands that may arise from your use or misuse of our
            services. We reserve the right to select our own legal counsel.</p>
        <h3>Limitation on liability</h3>
        <p>[name] is not liable for any damages that may occur to you as a result of
            your misuse of our website. [name] reserves the right to edit, modify, and
            change this Agreement any time. We shall let our users know of these changes
            through electronic mail. This Agreement is an understanding between [name] and
            the user, and this supersedes and replaces all prior agreements regarding the
            use of this website.</p>
    </div>
</template>

<style lang="scss">
    #terms-of-use {
        text-align: left;
        width: 45em;
        margin: 150px auto;
        h3,
        h4 {
            margin: 1em 0;
            font-weight: 600;
        }
        h5,
        h6 {
            margin: 1em 0;
            font-weight: 400;
            text-decoration: underline;
        }
        p {
            font-family: 'Artegra', helvetica, sans-serif;
            font-weight: 400;
        }
        ul {
            margin: 2em 0;
            padding: 0 0 0 1em;
            li {
                margin: 1em 0;
            }
        }
    }
</style>