import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Vue3TouchEvents from "vue3-touch-events";
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import { createMetaManager, defaultConfig, } from 'vue-meta'

const app = createApp(App)
app.use(router)
app.use(Vue3TouchEvents)
app.use(createMetaManager(false, {
  ...defaultConfig,
  meta: { tag: 'meta', nameless: true },
}))
app.component('vue-cookie-accept-decline', VueCookieAcceptDecline)
app.mount('#app')
 