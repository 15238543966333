<template>
  <div class="job-details">
    <div class="gemholder">
      <img class="gem" id="gem1" src="../assets/imgs/gems/crystal3_00008.png" alt="crystal-1">
    </div>
    <div class="gemholder">
      <img class="gem" id="gem2" src="../assets/imgs/gems/Crystal4_00017.png" alt="crystal-2">
    </div>
    <p v-if="loading"></p>
    <div v-else class="content">
      <router-link class="backBtn" to="/careers">◀&nbsp;&nbsp;Back to Jobs</router-link>
      <h2>{{ selectedJob.title }}</h2>
      <div class="quick-details">
        <h5>Department: {{ selectedJob.department }}</h5>
        <h4>{{ selectedJob.location.city }}</h4>
        <h5 v-if="selectedJob.salary.salary_from || selectedJob.salary.salary_to">Salary: £{{
          selectedJob.salary.salary_from }} - {{ selectedJob.salary.salary_to }}</h5>
      </div>
      <div class="blockOuterDetails">
        <div class="rainbowRule"></div>
        <div class="blockTextDetails">
          <div class="full-description" v-html="selectedJob.full_description"></div>
          <!-- <a class="apply-btn" :href="selectedJob.application_url" target="_blank"> Apply Now </a> -->
          <div class="LH_btn" id="apply">
            <a :href="selectedJob.application_url" target="_blank">
              <div>Apply Now&nbsp; ✦</div>
            </a>
          </div>
          <!-- <h1>{{ queryJob.title }}</h1> -->
          <!-- <applyForm/> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
// import applyForm from '../components/ContactForm.vue';
export default {
  data() {
    return {
      // loading: true,
      error: null,
      jobDetails: {},
    }
  },
  props: [
    "loading",
    "details",
    "jobs"
  ],
  components: {
    // applyForm
  },
  created() {
    this.$emit(this.$route.params.id)
  },
  mounted() {
  },
  computed: {
    selectedJob() {
      return this.jobs.filter((job) => {
        return job.shortcode == this.$route.params.id
      })[0]
    }
  }
}

</script>

<style lang="scss">
  .job-details {

    text-align: left;
    flex-grow: 1;

    @include for-phone-only {
      text-align: center;
    }
    .gemholder{
      position: relative;
      // overflow: hidden;
    }
    #gem1 {
      left: 0%;
      width: 80%;
      top: 0%;
      transform: translateY(-40%) translateX(0%) scaleY(-1);

      @include for-large-desktop-only() {
        top: 0vh;
        width: 70%;
      }
      @include for-phone-only() {
        transform: translateY(-102%) translateX(-55%) scaleY(-1);
        scale: 50%
      }
    }

    #gem2 {
      right: 0%;
      top: 25vh;
      width: 40%;
      @include for-large-desktop-only() {
        top: 15vh;
        width: 30%;
      }
      @include for-phone-only() {
        transform: translateY(-50%) translateX(15%);
        right: -100%;
        scale: 50%
      }
    }
    .content {
      @include for-phone-only {
        width: 100%;
        margin-top: 2em;

        h2 {
          margin: 0 auto;
        }

      }

      .backBtn {
        text-align: left;
        
        @include for-phone-only {
          margin-left: 1em;
        }
      }

      margin: 0 auto;
      // width: calc(45% + (2 * $vertRuleOffset));
      width: 45%;

      h2 {
        margin: 1em 0 .7em 0;
      }

      p {
        margin: 1.5em 0;
      }

      ul {
        margin: 1em 0;

        li {
          margin: 0 0 0.5em 0;
        }
      }

      .blockOuterDetails {
        display: flex;
        // width: calc(45% + 2em);
        width: calc(100% + (2 * $vertRuleOffset));
        // margin: 0 auto;
        // padding: 2em 0;
        margin-left: -2em;

        @include for-phone-only {
          width: calc(100% + $vertRuleOffset);
          padding: 1em 0 1em 0;
          margin-left: 1em;

        }

        div.rainbowRule {
          margin-top: 2em;
          border: 0;
          height: 120px;
          width: 3px;
          background: $gradSpectrum;
        }

        .blockTextDetails {
          width: calc(100%);
          margin: 0 2em 0 2em;
          text-align: left;

          @include for-phone-only {
            width: calc(70% + $vertRuleOffset);
            // margin: 0 1em 0 1em;
            margin: 0 1em 0 1em;
          }
        }
      }


    }
  }

  #apply {
    text-align: left;
    margin: 3em 0;
    @include for-phone-only {
      margin: 0 auto;
    }
  }
</style>