<template>
  <div id="careers">
    <div class="gemHolder">
      <img class="gem" id="gem1" src="../assets/imgs/gems/crystal3_00008.png" alt="crystal-1">
    </div>
    <div class="gemHolder">
      <img class="gem" id="gem2" src="../assets/imgs/gems/Crystal4_00017.png" alt="crystal-2">
    </div>
    <!-- <img class="gem" id="gem2" src="../assets/imgs/gems/Crystal4_00017.png" alt=""> -->

    <!--<img class="gem" id="gem3" src="../assets/imgs/gems/crystal3_00151.png" alt=""> -->
    <div class="content">
      <div class="intro-text">
        <h1 class="title">Careers</h1>
      </div>

      <h5>A selection of our current opportunities </h5>
      <div class="filters">
        <button class="filterBtn" v-for="department in this.departments" :key="department.id"
          @click="(event) => filter(department, event)"> {{ department }}</button>
      </div>

      <div id="jobs-list" v-if="!loading">
        <router-link class="job-listing" v-for="job in this.filteredJobs" :id="job.id" :key="job.id"
          :to="{ name: 'jobDetails', params: { id: job.shortcode } }" :details="job">
          <div class="job-info">
            <h4>{{ job.title }}</h4>
            <h6>{{ job.department }} - {{ job.location.city }}</h6>
            <h6 v-if="job.salary.salary_from || job.salary.salary_to">Salary: £{{ job.salary.salary_from }} -
              {{ job.salary.salary_to }}</h6>
          </div>
          <div class="arrow-right"></div>
        </router-link>
      </div>

      <div v-else>
        <h1>Loading</h1>
      </div>
      <div class="blockOuter">
        <div class="rainbowRule"></div>
        <div class="blockText">
          <h5>Can't find what you're looking for? </h5>
          <p>As a growing AAA development studio we are actively searching for top talent.</p>
          <p>If you believe you have something special, but don't see a specific role that matches your expertise, then please submit a speculative application.</p>
          <p>You will be contacted directly by one of our internal team, who will have full oversight of any upcoming roles that may be suitable for you.</p>
        </div>
      </div>

      <div class="LH_btn" href="#">
        <router-link to="/contact">
          <div>Get in touch&nbsp; ✦</div>
        </router-link>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // departments: [],
      filteredJobs: null
    }
  },
  props: [
    "jobs",
    "loading"
  ],
  mounted() {
    //if landing on the page from another route and data was already loaded
    if (!this.loading) {
      this.listJobs()
    }
  },
  watch: {
    //if landing on the page wait for data then trigger function
    loading: function () {
      this.listJobs()
    }
  },
  methods: {
    getAllDepartments() {
      for (let i = 0; i < this.jobs.length; i++) {
        if (!this.departments.includes(this.jobs[i].department)) {
          this.departments.push(this.jobs[i].department)
        }
      }
    },
    filter(value, event) {
      if (event.target.classList.contains("active")) {
        event.target.classList.remove("active")
        this.filteredJobs = this.jobs
      } else {
        let btns = document.getElementsByClassName("filterBtn")
        for (let i = 0; i < btns.length; i++) {
          btns[i].classList.remove("active")
        }
        event.target.classList.add("active")
        this.filteredJobs = this.jobs
        this.filteredJobs = this.filteredJobs.filter((job) => {
          return job.department == value
        })
      }
    },
    listJobs() {
      if (!this.loading) {
        this.filteredJobs = this.jobs
      } else {
        this.filteredJobs = null
      }
    }
  },
  computed: {
    departments() {
      let departments = []
      if (!this.loading) {
        if(this.jobs != undefined){
          for (let i = 0; i < this.jobs.length; i++) {
            if (!departments.includes(this.jobs[i].department)) {
              departments.push(this.jobs[i].department)
            }
          }
        }else{
          return null
        }
        return departments
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss">
#careers {
  // position: relative;
  overflow: hidden;
  flex-grow:1;
}

.gemHolder {
  position: relative;
  width: 100%;
  height: 100%;
}

.gem {
  position: absolute;
  // top: 0;

}

#gem1 {
  left: 0%;
  // width: 70%;
  top: 0%;
  transform: translateY(-50%) translateX(0%) scaleY(-1);

  @include for-phone-only() {
    transform: translateY(-102%) translateX(-55%) scaleY(-1);
    scale: 50%
  }
}

#gem2 {
  right: 0%;
  top: 0%;

  // transform: translateY(0%) translateX(0%);
  // width: 70%;
  // transform: translateY(-45%) scaleY(-1);
  @include for-phone-only() {
    transform: translateY(-50%) translateX(15%);
    right: -100%;
    scale: 50%
  }
}

#gem3 {
  @include for-phone-only() {
    transform: translateX(-50%) translateY(160%);
  }

  left: 0;
  width: 70%;
  transform: translateX(-40%) translateY(100%);
}

#jobs-list {
  @include for-phone-only {
    width: 95%;
  }

  padding: 2em 0 5em 0;
  width: 45%;
  margin: 0 auto;
}

.job-listing {
  @include for-phone-only {
    padding: 1em .9em;
    min-height: 6em;
  }

  margin: auto;
  padding: 1.2em 0;
  min-height: 8em;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  // border-image-source: linear-gradient(to right, #838383 65.77%, #83F8FF 71.41%, #FFEB85 76.37%, #FFC9F0 80.98%, #B3FABA 85.33%, #798EFE 89.24%, #838383 94.13%);
  display: flex;
  align-items: center;

  .job-info {
    margin: 0 auto;
  }

  .arrow-right {
    @include for-phone-only {
      display: none;
    }

    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid white;
  }

  a {
    padding: 0.5em 2em;
    margin: 1em 0 0 0;
    border: 1px solid #ffffff;
    border-radius: 2em;
    display: inline-block;
    background-color: #000;
  }
}

.filters {
  @include for-phone-only {
    width: 100%;
  }

  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-family: $SansFont;

  button {

    @include for-phone-only {
      margin: .5em;
      height: 2.5em;
    }

    font-family: $SansFont;
    font-weight: 500;
    text-decoration: none;

    width: 12em;
    height: 3em;
    margin: 1em;
    background: #000;
    padding: .1em 0 0 0;
    color: #fff;
    border: 2px solid;
    border-radius: 3em;
    cursor: pointer;
  }

  .active {
    background-color: #fff;
    color: #000;
  }
}

.blockOuter {
  include for-phone-only {
    padding: 4em 0;
  }
}

</style>