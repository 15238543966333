<template>
  <div id="carouselContainer">
    <div class="button buttonLeft" @click="panRight" v-if="!this.isMobile">
      <div class="arrow-left"></div>
    </div>
    <div class="carouselImgsContainer" ref="carousel">
      <div class="profileImgContainer" v-for="(person, index) in this.people" :key="index" :class="index==1 ? 'active' : 'profile'">
        <img class="profileImg" :src="person.img" :alt="person.name" v-touch:swipe.left="panLeft" v-touch:swipe.right="panRight">
        <div class="personDetails">
          <h6>{{ person.name }}</h6>
          <caption>{{ person.position }}</caption>
        </div>
      </div>
    </div>
    <div class="button buttonRight" @click="panLeft" v-if="!this.isMobile">
      <div class="arrow-right"></div>
    </div>
    <!-- <div id="carouselNavContainer">
      <div id="carouselNav">
        <svg class="dot" v-for="(dot, index) in parseInt(this.people.length)" :key="dot.index" :class="index==1 ? 'active' : 'profile'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 45 45" style="enable-background:new 0 0 45 45;" xml:space="preserve">
          <g>
            <path class="st0" d="M22.5,3.9c0,10.2-8.3,18.5-18.5,18.5c10.2,0,18.5,8.3,18.5,18.5c0-10.2,8.3-18.5,18.5-18.5C30.8,22.4,22.5,14.2,22.5,3.9z"/>
          </g>
        </svg>
      </div>
    </div> -->
  </div>
</template>

<script>
import gsap from 'gsap'
export default{
name: "carousel",
mounted(){
  console.log(this.isMobile)
},
props: [
  "isMobile"
],
data(){
  return{
    carouselAnimating: false,
    centreCarouselImg: 1,
    people:[
        {
          name: "Annie Clare",
          img: require("../assets/imgs/team/Annie-Clare-HR-Director.jpg"),
          position: "HR Director",
          bio: "Annie placeholder data"
        },
        {
          name: "Gavin Raeburn",
          img: require("../assets/imgs/team/Gavin-Raeburn-Head-of-Studio.jpg"),
          position: "Head of Studio",
          bio: "Gavin placeholder data"
        },
        {
          name: "Andy Ward",
          img: require("../assets/imgs/team/Andy-Ward-Development-Director.jpg"),
          position: "Development Director",
          bio: "Andy placeholder data"
        },
        {
          name: "Nick Wheelwright",
          img: require("../assets/imgs/team/Nick-Wheelwright-Director.jpg"),
          position: "Director",
          bio: "Nick placeholder data"
        },
        {
          name: "Jon Davis Hunt",
          img: require("../assets/imgs/team/Jon-Davis-Hunt-Creative-Director.jpg"),
          position: "Creative Director",
          bio: "Jon placeholder data"
        },
        {
          name: "John Chalkley",
          img: require("../assets/imgs/team/John-Chalkley-Head-of-Talent-Acquisition.jpg"),
          position: "Head of Talent Acquisition",
          bio: "John placeholder data"
        },
        {
          name: "Pete Ridgway",
          img: require("../assets/imgs/team/Pete-Ridgway-Technical-Art-Director.jpg"),
          position: "Technical Art Director",
          bio: "Pete placeholder data"
        },
        {
          name: "Tom Perry",
          img: require("../assets/imgs/team/Tom Perry_ Lead Engineer.jpg"),
          position: "Lead Engineer",
          bio: "Tom placeholder data"
        },
        {
          name: "Ben Thaker Fell",
          img: require("../assets/imgs/team/Ben-Thaker-Fell-Principal-Game-Designer.jpg"),
          position: "Principal Game Designer",
          bio: "Ben placeholder data"
        },
        {
          name: "Barry Zubel",
          img: require("../assets/imgs/team/Barry-Zubel-IT-Director.jpg"),
          position: "IT Director",
          bio: "Barry placeholder data"
        },
      ]
  }
},
methods: {
  panLeft(){
    const imgs = document.getElementsByClassName("profileImgContainer")
    // const dots = document.getElementsByClassName("dot")
    if(!this.isMobile){
      if(!this.carouselAnimating) {
          if(this.centreCarouselImg < imgs.length-1){
          this.carouselAnimating = true
          gsap.to(".carouselImgsContainer", {
            duration: .5,
            x: "-=33.3333%" ,
            ease: "power3.out",
            onComplete: ()=>{
              this.carouselAnimating = false
              this.centreCarouselImg++
            }
          })
          imgs[this.centreCarouselImg+1].classList.add("active")
          imgs[this.centreCarouselImg].classList.remove("active")
          // dots[this.centreCarouselImg+1].classList.add("activeDot")
          // dots[this.centreCarouselImg].classList.remove("activeDot")

        }
      }
    }else{
      if(!this.carouselAnimating) {
        if(this.centreCarouselImg < imgs.length-1){
          this.carouselAnimating = true
          gsap.to(".carouselImgsContainer", {
            duration: .5,
            x: "-=83%" ,
            ease: "power3.out",
            onComplete: ()=>{
              this.carouselAnimating = false
              this.centreCarouselImg++
            }
          })
          imgs[this.centreCarouselImg+1].classList.add("active")
          imgs[this.centreCarouselImg].classList.remove("active")
          // dots[this.centreCarouselImg+1].classList.add("activeDot")
          // dots[this.centreCarouselImg].classList.remove("activeDot")
        }
      }
    }
  },
  panRight(){
    let imgs = document.getElementsByClassName("profileImgContainer")
    // const dots = document.getElementsByClassName("dot")
    if(!this.isMobile){
      if(!this.carouselAnimating) {
        // if (imgs[0].getBoundingClientRect().left < 0) {
          if(this.centreCarouselImg > 0){
          this.carouselAnimating = true
          gsap.to(".carouselImgsContainer", {
            duration: .5,
            x: "+=33.3333%" ,
            ease: "power3.out",
            onComplete: ()=>{
              this.carouselAnimating = false
              this.centreCarouselImg--
            }
          })
          imgs[this.centreCarouselImg-1].classList.add("active")
          imgs[this.centreCarouselImg].classList.remove("active")
          // dots[this.centreCarouselImg-1].classList.add("activeDot")
          // dots[this.centreCarouselImg].classList.remove("activeDot")
        }
      }
    }else{
      if(!this.carouselAnimating) {
        // if (imgs[0].getBoundingClientRect().left < 0) {
          if(this.centreCarouselImg > 0){
          this.carouselAnimating = true
          gsap.to(".carouselImgsContainer", {
            duration: .5,
            x: "+=83%" ,
            ease: "power3.out",
            onComplete: ()=>{
              this.carouselAnimating = false
              this.centreCarouselImg--
            }
          })
          imgs[this.centreCarouselImg-1].classList.add("active")
          imgs[this.centreCarouselImg].classList.remove("active")
          // dots[this.centreCarouselImg-1].classList.add("activeDot")
          // dots[this.centreCarouselImg].classList.remove("activeDot")
        }
      }
    }
  },
}
}
</script>

<style lang="scss" scoped>
  #carouselContainer{
    display: flex;
    .button{
      position: absolute;
      z-index: 5;
      top: 0;
      height: 100%;
      width: 5%;
      background-color: #000000aa;
      cursor: pointer;
    }
    .buttonLeft{
      left: 0;
    }
    .buttonRight{
      left: 100vw;
      transform: translateX(-100%);
    }
    .arrow-right {
      width: 0;
      height: 0;
      top: 50%;
      margin: auto;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid white;
    }
    .arrow-left {
      width: 0;
      height: 0;
      top: 50%;
      margin: auto;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid white;
    }
    #carouselNavContainer{
      position: absolute;
      bottom: -60px;
      width: 100%;
      height: 30px;
      left: 0;
      #carouselNav{
        width: 30%;
        margin: 0 auto;
        text-align: center;
        img, svg{
          width: 25px;
          margin: 0 5px 0 5px;
        }
      }
      .st0{
        stroke:#FFFFFF;
      }
      .activeDot{
        fill:#FFFFFF;
      }
    }
  }




  .carouselImgsContainer{
    @include for-phone-only(){
      transform: translateX(-75%);
    }
    display: inline-flex;
    flex-wrap: nowrap;
    .profileImgContainer{
      @include for-phone-only(){
        width: 80%;
      }
      width: 30%;
      margin: 0 1.666%;
      flex: 1 0 auto;
      cursor: pointer;
      filter: brightness(60%);
      border-bottom: 3px solid;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
      border-image-slice: 1;
      transition: all .5s;
      border-image-source: linear-gradient(90deg, #838383 50.78%, #83F8FF 58.91%, #FFEB85 66.19%, #FFC9F0 74.57%, #B3FABA 82.22%, #798EFE 90.71%, #838383 98.82%);
      &:hover{
        filter: brightness(100%);
        transition: all .5s;
      }
      img{
        width: 100%;
        vertical-align: bottom;
      }
      .personDetails{
        position: absolute;
        bottom: 0;
        background: #00000077;
        width: 100%;
        padding: 0.5em 1em 0 1em;
        h6{
          margin: 0 0 0.5em 0;
        }
        caption{
          display: block;
          text-align: left;
          margin: 0 0 0.5em 0;
        }
      }
    }
    .active{
      filter: brightness(100%);
      transition: all .5s;
    }
  }
</style>