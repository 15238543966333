<template>
  <div id="header" ref="header">
    <div id="logo" ref="logo">
      <router-link to="/" title="Lighthouse Games"><img src="../assets/imgs/logo-horizontal.svg" alt=""></router-link>
    </div>
    <div id="nav">
      <ul>
        <li v-if="!isMobile"><router-link to="/careers">Join Us</router-link></li>
        <li v-if="!isMobile"><router-link to="/contact">Contact</router-link></li>
        <li v-if="!isMobile">
          <a class="noLine" href="https://www.linkedin.com/company/lighthousegames" target="_blank">
            <img src="../assets/imgs/linkedIn.svg" alt="LinkedIn">
          </a>
        </li>
        <!-- <li v-if="!isMobile"><router-link to="" @click="logout">log out</router-link></li> -->
        
        <li v-if="isMobile" ref="burgerButton" id="burgerButton" @click="burgerMenu">
          <div class="topBar" ref="topBar"></div>
          <div class="bottomBar" ref="bottomBar"></div>
        </li>
      </ul>
    </div><!--end nav-->
  </div>
  <ul id="burgerMenu" v-if="isMobile" ref="burgerMenu">
    <!-- <li><router-link to="/" @click="burgerMenu(); close()"><img src="../assets/imgs/Logo-mark.svg" alt=""></router-link></li> -->
    <li><router-link to="/" @click="burgerMenu(); close()"><span>Home</span></router-link></li>
    <li><router-link to="/careers" @click="burgerMenu"><span>Join Us</span></router-link></li>
    <li><router-link to="/contact" @click="burgerMenu"><span>Contact</span></router-link></li>
    <!-- <li><router-link to="" @click="logout">log out</router-link></li> -->
  </ul>
</template>

<script>
import gsap from 'gsap'
export default {
  name: "lighthouseHeader",
  data() {
    return {
      showBurgerMenu: false,
    }
  },
  props: [
    "isMobile"
  ],
  methods: {
    // logout() {
    //   this.$emit('logout')
    // },
    burgerMenu() {
      if (!this.showBurgerMenu) {
        gsap.to(".topBar", { duration: .4, rotate: 45, y: "5px" })
        gsap.to(".bottomBar", { duration: .4, rotate: -45, y: "-3px" })
        gsap.to("#burgerMenu", { duration: .6, left: 0, ease: "power3.out", onComplete: () => { this.showBurgerMenu = !this.showBurgerMenu } })
        document.body.style.overflow = "hidden"
      } else {
        gsap.to(".topBar", { duration: .4, rotate: 0, y: 0 })
        gsap.to(".bottomBar", { duration: .4, rotate: 0, y: 0 })
        gsap.to("#burgerMenu", { duration: .6, left: "110vw", ease: "power3.out", onComplete: () => { this.showBurgerMenu = !this.showBurgerMenu } })
        document.body.style.overflow = "auto"
      }
    },
  }
}
</script>

<style lang="scss" scoped>
$headerHeight: 90px;
#header {
  height: $headerHeight;
  padding: 0 1em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  transition: all 0.4s ease;
  z-index: 25;
  background-color: #0000009b;
  backdrop-filter: blur(5px);
  &.sticky {
    height: $headerHeight - 20px;
  }
  #logo {
    @include for-phone-only {
      margin-left: 0;
    }
    width: 200px;
    margin-left: 3em;
    img {
      vertical-align: middle;
    }
  }
  #nav {
    width: 50%;
    font-weight: 400;
    ul {
      margin: 0;
      padding: 0;
      text-align: right;
      list-style-type: none;
      li {
        width: auto;
        margin: 0;
        display: inline-block;
        padding: 0 3% 0 3%;
        vertical-align: middle;
        .router-link-active {
          &:after {
          display:block;
          content: '';
          border-bottom: 2px solid transparent; 
          border-top: 0px; 
          transform: scaleX(1);  
          transition: transform 200ms ease-in-out;
          border-image-slice: 1;
          border-image-source: linear-gradient(90deg, #838383 50.78%, #83F8FF 58.91%, #FFEB85 66.19%, #FFC9F0 74.57%, #B3FABA 82.22%, #798EFE 90.71%, #838383 98.82%);
          }
        }
        a {
          text-align: center;
          color: inherit;
          font-size: ms(1);
          &:after {
            display:block;
            content: '';
            border-bottom: 2px solid transparent; 
            border-top: 0px; 
            border-image-slice: 1;
            border-image-source: linear-gradient(90deg, #838383 50.78%, #83F8FF 58.91%, #FFEB85 66.19%, #FFC9F0 74.57%, #B3FABA 82.22%, #798EFE 90.71%, #838383 98.82%);
            transform: scaleX(0);  
            transform-origin:  100% 0%;
            transition: transform 200ms ease-in-out;
          }
          &:hover:after {
            transform: scaleX(1);
            transform-origin:  0% 50%;
            border-bottom: 2px solid;
          }
          .noLine {
          &:after {
            border: none;
          }
          &:hover:after {
            border: none;
          }
        }
          img {
            vertical-align: middle;
          }
        }
      }
      .noLine {
        &:after {
          border: none;
        }
        &:hover:after {
          border: none;
        }
      }
    }
  }
}
.topBar,
.bottomBar {
  width: 30px;
  height: 2px;
  border-radius: 2px;
  background-color: #ffffff;
  margin: 6px 0;
}
ul#burgerMenu {
  padding: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 110vw;
  z-index: 22;
  background-color: #000000;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  li {
    padding: 1em;
    line-height: 4rem;
    text-align: center;
    font-size: ms(2);
    font-weight: 700;
    margin: 0;
    .router-link-active span{
          border-bottom: 2px solid;
          border-image-slice: 1;
          border-image-source: linear-gradient(90deg, #838383 50.78%, #83F8FF 58.91%, #FFEB85 66.19%, #FFC9F0 74.57%, #B3FABA 82.22%, #798EFE 90.71%, #838383 98.82%);
        }
  }
  a {
    color: white;
    img {
      width: 20%;
    }
  }
}
</style>