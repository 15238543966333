<template>
  <div class="home" tabindex="0" @keydown.esc="showModal = false">
    <modal v-if="showModal" :selectedPerson="selectedPerson" @closeModal="showModal=false"/>
    <div id="video">
      <video autoplay muted loop ref="heroVideo">
        <source src='https://res.cloudinary.com/diva-agency/video/upload/v1679579714/Liquid-Crystal.mp4' type='video/mp4'>
      </video>
    </div>
    <div id="homeGem1">
      <img src="../assets/imgs/gems/crystal-left.png"  alt="crystal-left"/>
    </div>
    <div id="homeGem2">
      <img src="../assets/imgs/gems/crystal-right.png" alt="crystal-right"/>
    </div>
    <div class="homeGrid">
      <div id="hero-wrapper">
        <div id="hero">
          <img id="hero-logo" src="../assets/imgs/Logo-stacked.svg" alt="logo">
          <div id="exploreButton" class="LH_btn">
            <a class="exploreBtn button" @click="scrollDown">
              <div>✦ &nbsp; <span class="btnTextShift">Explore Lighthouse</span></div>
            </a>
          </div>
        </div>
      </div>
      <div id="exploreDest" ref="exploreDest"></div>
      <div id="blockIntroOuter" class="blockOuterLeft row2" ref="startExplore">
        <div class="rainbowRule"></div>
        <div class="blockIntro">
          <h5>Shine Bright</h5>
          <p>Lighthouse works at the bleeding edge of video game development, and our studio attracts and nurtures the best talent in the industry.

          </p>
          <p>
            We have a passion for quality, and put ambition and delivery front and centre.
          </p>
        </div>
      </div>

      <!-- GRADIENT BLOCKQUOTE -->
      <!-- <div id="blockQuoteRightOuterNew" class="row4">
        <div id="blockQuoteRightInnerNew">
          <div class="blockQuoteRightNew">
            <p>
              Using our knowledge and expertise we will create a new, genre-defining franchise.
            </p>
          </div>
        </div>
      </div> -->

      <!-- ORIGINAL BLOCKQUOTE -->
      <div id="blockQuoteRightOuter" class="row4">
        <div class="blockQuoteRight">
          <p>
            Using our knowledge and expertise we will create a new, genre-defining franchise.
          </p>
        </div>
      </div>

      <div id="blockTeamPhoto" class="row5">
        <h3 class="titleWork">Working at Lighthouse</h3>
        <img id="teamPhoto" src="../assets/imgs/team/Lighthouse-team.jpg" alt="Lighthouse-team-photo"/>
      </div>
      <div id="blockBrightOuter" class="blockOuterLeft row6">
        <div class="rainbowRule"></div>
        <div class="blockBright">
          <h5>Bright Lights, Big Ambitions</h5>
          <p>Lighthouse is a home for ambitious developers who value collaboration, and possess a determination to deliver quality.</p>

          <p>You will join some of the leading lights in AAA game development, who will support you in creating
            the best work of your career in an environment which fosters openness and respect.</p>

          <div id="exploreButton" class="LH_btn">
            <router-link to="/careers" class="exploreBtn button">
              <div><span class="btnTextShift">Join Lighthouse</span> &nbsp; ✦ </div>
            </router-link>
          </div>
        </div>
      </div>
      <div id="blockQuoteLeftOuter" class="row7">
        <div class="blockQuoteLeft">
          <p>
            We want talent to flourish in our state-of-the-art studio, and would welcome experienced developers with a passion for mentoring.
          </p>
        </div>
      </div>
      <div id="peopleSection" class="row8">
        <!-- <carousel :isMobile="isMobile"/> -->
        <h3>Lighthouse People</h3>
        <carousel :isMobile="isMobile"/>
      </div>

      <div id="blockBleedingEdgeOuter" class="blockOuterLeft row9">
        <div class="rainbowRule"></div>
        <div class="blockBleedingEdge">
          <h5>The Bleeding Edge of Game Development</h5>

          <p>Lighthouse is defined by the pedigree of its talent and together we're embarking on a journey to make something truly special. These are just some of the people who have chosen to join us.</p>

          <div id="exploreButton" class="LH_btn">
            <router-link to="/careers" class="exploreBtn button">
              <div>✦ &nbsp; <span class="btnTextShift">Explore Jobs</span></div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {gsap} from 'gsap';
import modal from '../components/modal.vue'
import carousel from '../components/Carousel.vue'
// import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: 'Home',
  data(){
    return{
      showModal: false,
      selectedPerson: "Placeholder data",
      swipeDirection: null,
      people:[
        {
          name: "Annie Clare",
          img: require("../assets/imgs/team/Annie-Clare-HR-Director.jpg"),
          position: "HR Director",
          bio: "Annie placeholder data"
        },
        {
          name: "Gavin Raeburn",
          img: require("../assets/imgs/team/Gavin-Raeburn-Head-of-Studio.jpg"),
          position: "Head of Studio",
          bio: "Gavin placeholder data"
        },
        {
          name: "Andy Ward",
          img: require("../assets/imgs/team/Andy-Ward-Development-Director.jpg"),
          position: "Development Director",
          bio: "Andy placeholder data"
        },
        {
          name: "Nick Wheelwright",
          img: require("../assets/imgs/team/Nick-Wheelwright-Director.jpg"),
          position: "Director",
          bio: "Nick placeholder data"
        },
        {
          name: "Jon Davis Hunt",
          img: require("../assets/imgs/team/Jon-Davis-Hunt-Creative-Director.jpg"),
          position: "Creative Director",
          bio: "Jon placeholder data"
        },
        {
          name: "John Chalkley",
          img: require("../assets/imgs/team/John-Chalkley-Head-of-Talent-Acquisition.jpg"),
          position: "Head of Talent Acquisition",
          bio: "John placeholder data"
        },
        {
          name: "Pete Ridgway",
          img: require("../assets/imgs/team/Pete-Ridgway-Technical-Art-Director.jpg"),
          position: "Technical Art Director",
          bio: "Pete placeholder data"
        },
        {
          name: "Tom Perry",
          img: require("../assets/imgs/team/Tom Perry_ Lead Engineer.jpg"),
          position: "Lead Engineer",
          bio: "Tom placeholder data"

        },
        {
          name: "Ben Thaker Fell",
          img: require("../assets/imgs/team/Ben-Thaker-Fell-Principal-Game-Designer.jpg"),
          position: "Principal Game Designer",
          bio: "Ben placeholder data"
        },
        {
          name: "Barry Zubel",
          img: require("../assets/imgs/team/Barry-Zubel-IT-Director.jpg"),
          position: "IT Director",
          bio: "Barry placeholder data"
        },
      ]

    }
  },
  components: {
    modal,
    carousel
  },
  props: [
    "isMobile"
  ],
  created() {
  },
  mounted() {
    this.videoSize()
    window.addEventListener("resize", ()=>{
    this.videoSize()})

    // this.scrollVideo();
  },
  methods: {
    panLeft(){
      let imgs = document.getElementsByClassName("profileImgContainer")
      if(!this.isMobile){
        if(!this.carouselAnimating) {
            if(this.centreCarouselImg < imgs.length-1){
            this.carouselAnimating = true
            gsap.to(".carouselImgsContainer", {
              duration: .5,
              x: "-=33.3333%" ,
              ease: "power3.out",
              onComplete: ()=>{
                this.carouselAnimating = false
                this.centreCarouselImg++
              }
            })
            imgs[this.centreCarouselImg+1].classList.add("active")
            imgs[this.centreCarouselImg].classList.remove("active")
          }
        }
      }else{
        if(!this.carouselAnimating) {
          if(this.centreCarouselImg < imgs.length-1){
            this.carouselAnimating = true
            gsap.to(".carouselImgsContainer", {
              duration: .5,
              x: "-=83%" ,
              ease: "power3.out",
              onComplete: ()=>{
                this.carouselAnimating = false
                this.centreCarouselImg++
              }
            })
            imgs[this.centreCarouselImg+1].classList.add("active")
            imgs[this.centreCarouselImg].classList.remove("active")
          }
        }
      }
    },
    panRight(){
      let imgs = document.getElementsByClassName("profileImgContainer")
      if(!this.isMobile){
        if(!this.carouselAnimating) {
          // if (imgs[0].getBoundingClientRect().left < 0) {
            if(this.centreCarouselImg > 0){
            this.carouselAnimating = true
            gsap.to(".carouselImgsContainer", {
              duration: .5,
              x: "+=33.3333%" ,
              ease: "power3.out",
              onComplete: ()=>{
                this.carouselAnimating = false
                this.centreCarouselImg--
              }
            })
            imgs[this.centreCarouselImg-1].classList.add("active")
            imgs[this.centreCarouselImg].classList.remove("active")
          }
        }
      }else{
        if(!this.carouselAnimating) {
          // if (imgs[0].getBoundingClientRect().left < 0) {
            if(this.centreCarouselImg > 0){
            this.carouselAnimating = true
            gsap.to(".carouselImgsContainer", {
              duration: .5,
              x: "+=83%" ,
              ease: "power3.out",
              onComplete: ()=>{
                this.carouselAnimating = false
                this.centreCarouselImg--
              }
            })
            imgs[this.centreCarouselImg-1].classList.add("active")
            imgs[this.centreCarouselImg].classList.remove("active")
          }
        }
      }
    },
    videoSize(){
      let width = window.innerWidth
      let height = width * 1.45833
      const video = document.querySelector("#video video")
      video.style.width = width + "px"
      video.style.height = height + "px"
    },
    showBio(value){
      this.selectedPerson = value
      this.showModal = true
    },
    scrollDown(){
      const scrollTarget = this.$refs.exploreDest
      scrollTarget.scrollIntoView({ behavior: "smooth" })
    },
    // scrollVideo() {
    //   const videoScroll = document.querySelector("video");

    //   let tl = gsap.timeline({
    //     scrollTrigger: {
    //       trigger: "video3",
    //       start: "top top",
    //       end: "bottom+=200% bottom",
    //       scrub: true,
    //       markers: true
    //     }
    //   });

    //     tl.to(videoScroll, { currentTime: videoScroll.duration });

    //   // Dealing with devices
    //   function isTouchDevice() {
    //     return (
    //       "ontouchstart" in window ||
    //       navigator.maxTouchPoints > 0 ||
    //       navigator.msMaxTouchPoints > 0
    //     );
    //   }
    //   if (isTouchDevice()) {
    //     videoScroll.play();
    //     videoScroll.pause();
    //   }
    // }
  },
  computed: {
  }
}
</script>

<style lang="scss">
#video {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  overflow-x: hidden;
}

#homeGem1 {
  @include for-phone-only(){
    width: 80%;
    top: 8%;
  }
  @include for-tablet-portrait-only(){
    top: 8%;
  }
  @include for-large-desktop-only(){
    top: 15%;
  }
  width: 80%;
  position: absolute;
  left: 0;
  top: 15%;
  overflow: hidden;
  img{
    width: 100%;
  }
}

#homeGem2 {
  @include for-phone-only(){
    width: 100%;
    top: 53%;
  }
  @include for-tablet-portrait-only(){
    top: 45%;
    width: 75%;
  }
  @include for-large-desktop-only(){
    top: 48%;
    width: 45%;
  }
  position: absolute;
  right: 0;
  width: 70%;
  top: 45%;
  overflow: hidden;
  img{
    width: 100%;
  }
}


.homeGrid {
  @include for-phone-only(){
    grid-template-columns: 1fr 80% 1fr;
    grid-template-rows: 100vh auto auto auto auto auto auto auto auto auto auto;
  }
  @include for-tablet-portrait-only(){
    grid-template-columns: 1fr 80% 1fr;
    grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto;
  }
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 35% 35% 1fr;
  grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto;

  .row1{
    grid-row: 1;
  }
  .row2{
    grid-row: 2;
  }
  .row3{
    grid-row: 3;
  }
  .row4{
    grid-row: 4;
  }
  .row5{
    grid-row: 5;
  }
  .row6{
    grid-row: 6;
  }
  .row7{
    grid-row: 7;
  }
  .row8{
    grid-row: 8;
  }
  .row9{
    grid-row: 9;
  }
  .row10{
    grid-row: 10;
  }

  #hero-wrapper {
    @include for-tablet-portrait-only(){
    grid-column: span 3;
    }
    @include for-phone-only(){
    grid-column: span 3;
    align-self: center;
    }
    grid-column: span 4;
    position: relative;
    overflow-x: hidden;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 5em;

    #hero {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: center;
      #hero-logo {
        padding: 120px 0 0 0;
        width: 50%;
        @include for-phone-only() {
          width: 70%;
        }
        @include for-tablet-portrait-only() {
          width: 70%;
        }
      }
      #exploreButton {
          margin-top: 6em;

        .button {
          font-family: $SansFont;
          font-size: 1.2em;
          font-weight: 700;
          line-height: 1.2em;
          background: #1b1b1b;
          color: $link;
          border-radius: 3em;
          cursor: pointer;

          @include for-phone-only() {
            font-size: .7em;
          }

          &:hover {
            transition: all .5s;
          }
        }
      }
    }
  }

  #exploreDest {
    margin-top: -70px;
  }


  #blockIntroOuter{
    @include for-phone-only(){
      grid-column: 2 / 3;
      width: unset;
      margin: 0 0 0 calc(-1em - 3px);
    }
    grid-column: 2 / span 2;
    margin: 0 auto 0 auto;
    width: 70%;
    .blockIntro{
      @include for-phone-only(){
        margin: .5em 0 0 1em;

      }
      width: calc(100% - 1em);
      margin: .5em 0 0 2em;
      text-align: left;
      h5{
        margin: 0 0 1.5em 0;
      }
    }
  }

  #blockQuoteRightOuterNew {

    @include for-phone-only() {
      grid-column: 2/span 2;
      padding: .4em 10%;
      margin: 4em -5px 0 0;
    }

    @include for-tablet-portrait-only() {
      grid-column: 2/span 2;
      padding: .4em 10%;
    }

    grid-column: 3/span 2;
    margin: 6em -5px 0 0;
    position: relative;
    background: #00000077;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 10em 0 0 10em;
      padding: 3px;
      background: $gradSpectrumBtn;
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    #blockQuoteRightInnerNew {

      .blockQuoteRightNew {

        @include for-phone-only() {
          margin: 0;
          p {
            font-size: 1.125rem;
          }
        }

        @include for-tablet-portrait-only() {
          margin: 0;
        }

        height: 100%;
        text-align: right;
        margin: 0 30% 0 0;
        font-size: 1.0em;
        font-weight: 600;
        line-height: 1.5em;
        align-items: end;

        p {
          padding: 2em 0;
          margin: 0;
        }

      }

    }

  }


  /* ORIGINAL WHITE BORDER WITH TRANSPARENCY */
  #blockQuoteRightOuter {
    @include for-phone-only(){
      grid-column: 2/span 2;
      padding: .4em 10%;
      margin: 4em -5px 0 0;
    }
    @include for-tablet-portrait-only(){
      grid-column: 2/span 2;
      padding: .4em 10%;
    }
    grid-column: 3/span 2;
    padding: .4em 3em;
    background: #00000077;
    color: $link;
    border: 2px solid;
    border-radius: 10em 0 0 10em;
    margin: 6em -5px 0 0;
    .blockQuoteRight {
      @include for-phone-only(){
        margin: 0;
        // font-size: .7em;

        p {
          font-size: 1.125rem;
        }
      }
      @include for-tablet-portrait-only(){
        margin: 0;
      }
      text-align: right;
      padding: 2em 0;
      margin: 0 28% 0 0;
      font-size: 1.0em;
      font-weight: 600;
      line-height: 1.5em;
      align-items: end;
      p{
        margin: 0;
      }
    }
  }


  /*  WITH GRADIENT BORDER BUT OPAQUE */
  // #blockQuoteRightOuterNew {

  //   // @include for-phone-only(){
  //   //   grid-column: 2/span 2;
  //   //   padding: .4em 10%;
  //   //   margin: 4em -5px 0 0;
  //   // }
  //   // @include for-tablet-portrait-only(){
  //   //   grid-column: 2/span 2;
  //   //   padding: .4em 10%;
  //   // }

  //   grid-column: 3/span 2;
  //   padding: 2px;
  //   background-image: $gradSpectrumBtn;
  //   // background-color: $link;
  //   border: 2px solid;
  //   border-radius: 10em 0 0 10em;
  //   margin: 6em -5px 0 0;

  //   #blockQuoteRightInnerNew {

  //     background: #1b1b1b;
  //     border-radius: 10em 0 0 10em;
  //     border: none;

  //     .blockQuoteRightNew {

  //       // @include for-phone-only(){
  //       //   margin: 0;
  //       //   // font-size: .7em;

  //       //   p {
  //       //     font-size: 1.125rem;
  //       //   }

  //       // }

  //       // @include for-tablet-portrait-only(){
  //       //   margin: 0;
  //       // }

  //       // border-radius: 10em 0 0 10em;
  //       height: 100%;
  //       // background: #1b1b1b;
  //       text-align: right;
  //       // padding: 2em 0;
  //       margin: 0 30% 0 0;
  //       font-size: 1.0em;
  //       font-weight: 600;
  //       line-height: 1.5em;
  //       align-items: end;

  //       p{
  //         padding: 2em 0;
  //         margin: 0;
  //       }

  //     }

  //   }

  // }


  #blockTeamPhoto {
    @include for-phone-only(){
      grid-column: 2 / 3;
    }
    @include for-tablet-portrait-only(){
      grid-column: 2 / 3;
    }
    grid-column: 2 / 4;
    object-fit: cover;
    margin: 8em 0 0 0;
    img {
      width: 100%;
      border-bottom: 3px solid;
      border-top: 0px;
      border-right: 0px;
      border-left: 0px;
      vertical-align: bottom;
      border-image-slice: 1;
      border-image-source: linear-gradient(90deg, #838383 50.78%, #83F8FF 58.91%, #FFEB85 66.19%, #FFC9F0 74.57%, #B3FABA 82.22%, #798EFE 90.71%, #838383 98.82%);
    }
    .titleWork {
      text-align: right;
      margin: 0 auto 1em 0

    }
  }


  .blockOuterLeft {
    @include for-phone-only(){
      margin: 0;
      padding: 3em 0;
    }
    display: flex;
    width: 80%;
    margin: 0 auto;
    padding: 5em 0 2em 0;
    div.rainbowRule {
      margin-top: 0.6em;
      border: 0;
      height: 120px;
      width: 3px;
      background: $gradSpectrum;
    }
  }


  #blockBrightOuter {
    @include for-phone-only(){
    margin: .5em auto 0 calc(-1em - 3px);
    }
    margin: .5em auto 0 calc(-2em - 3px);
    grid-column: 2 / 3;
    text-align: left;
    margin: .5em auto 0 calc(-2em - 3px);
    text-align: left;
    width: unset;
    .blockBright{
      @include for-phone-only(){
        margin: .5em 0 0 1em;
      }
      margin: .5em 0 0 2em;
      text-align: left;
      width: calc(100% - 1em);
    }

    h5 {
      text-align: left;
      // font-size: 2em;
      margin: 0 0 1.5em 0;
    }
    .LH_btn{
      margin: 3em 0 0 0;
    }
    .button {
      display: inline-block;
      font-family: $SansFont;
      font-size: 1.2em;
      font-weight: 700;
      line-height: 1.2em;
      background: #1b1b1b;
      color: $link;
      border-radius: 3em;
      cursor: pointer;

      &:hover {
        transition: all .5s;
      }
    }
  }



  #blockQuoteLeftOuter {
    @include for-phone-only(){
      grid-column: 1/span 2;
    }
    grid-column: 1/span 2;
    padding: .4em 3em;
    background: #00000077;
    color: $link;
    border: 2px solid;
    border-radius: 0 10em 10em 0;
    margin: 6em 0 7em -5px;
    .blockQuoteLeft {
      @include for-phone-only(){
        margin: 0;
        p {
          font-size: 1.125rem;
        }
      }
      text-align: left;
      margin: 0 0 0 28%;
      padding: 2em 0;
      font-size: 1.0em;
      font-weight: 600;
      line-height: 1.5em;
      p{
        margin: 0;
      }
    }
  }


  #peopleSection{
    @include for-phone-only(){
    grid-column: 1 / span 3;
    }
    grid-column: 1 / span 4;
    width: 100vw;
    text-align: left;
    h3{
      margin: 0 auto 1em 15%;
    }
  }

  #blockBleedingEdgeOuter{
    @include for-phone-only(){
    grid-column: 2 / 3;
    margin: 3em 0 0 calc(-1em - 3px);
    }
    grid-column: 2 / 3;
    width: unset;
    margin: 5em 0 0 calc(-2em - 3px);
    h5{
      margin: 0 0 1.5em 0;
    }
    .blockBleedingEdge{
      @include for-phone-only(){
        margin: .5em 0 0 1em;
      }
      margin: .5em 0 0 2em;
      text-align: left;
      width: calc(100% - 1em);
    }
    .LH_btn{
      margin: 3em 0 0 0;
    }
  }
}
</style>

