<template>
    <div class="wrapper">
      <div id="loading">
        <div id="loading-logo">
          <svg id='loading-icon' version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 112.2 120" style="enable-background:new 0 0 112.2 120;" xml:space="preserve">
            <g>
              <path class="st0" d="M108.1,30.1L108.1,30.1c-5.5-9.6-15.5-15.1-25.8-15.4C76.8,5.8,67.1,0,56,0h0C45,0,35.3,5.8,29.8,14.5
                C19.5,14.9,9.7,20.4,4.1,29.9l0,0C-1.4,39.6-1.2,50.9,3.7,60c-4.9,9.1-5.1,20.4,0.5,30.1l0,0c5.5,9.6,15.4,15.1,25.7,15.4
                C35.3,114.2,45,120,56,120h0c11.1,0,20.8-5.8,26.3-14.6c10.3-0.3,20.2-5.8,25.8-15.4l0,0c5.5-9.6,5.3-20.9,0.5-29.9
                C113.4,50.9,113.6,39.6,108.1,30.1z M104.5,32.1c4.4,7.5,4.6,16.3,1.5,23.8c-2.4-3.2-5.5-6-9.2-8.2l-9.8-5.7V30.9
                c0-4.3-0.9-8.3-2.4-12C92.6,19.9,100.1,24.5,104.5,32.1z M79.9,101.3c-4.1-0.2-8.2-1.4-12-3.6l-7.6-4.4l22.5-13v8.8
                C82.8,93.5,81.8,97.6,79.9,101.3z M56.2,90.9l-27-15.6V44.6l27-15.6l26.6,15.4v31.2L56.2,90.9z M29.2,89.1v-9l22.9,13.2l-7.8,4.5
                c-3.8,2.2-8,3.4-12.1,3.6C30.3,97.7,29.2,93.5,29.2,89.1z M25.1,73l-7.6-4.4c-3.8-2.2-6.8-5.2-9.1-8.6c2.2-3.4,5.3-6.4,9.1-8.6
                l7.6-4.4V73z M32.2,18.6c4.1,0.2,8.3,1.3,12.1,3.6l7.8,4.5L29.2,39.9v-9C29.2,26.5,30.3,22.3,32.2,18.6z M82.8,30.9v8.8l-22.5-13
                l7.6-4.4c3.8-2.2,7.9-3.3,12-3.6C81.8,22.4,82.8,26.5,82.8,30.9z M86.9,46.8l7.8,4.5c3.8,2.2,6.9,5.2,9.1,8.7
                c-2.2,3.5-5.3,6.5-9.1,8.7l-7.8,4.5V46.8z M56,4.1c8.8,0,16.5,4.2,21.4,10.7c-4,0.5-7.9,1.8-11.6,3.9l-9.7,5.6l-9.8-5.7
                c-3.7-2.1-7.7-3.4-11.7-3.9C39.6,8.3,47.3,4.1,56,4.1z M7.7,32c4.4-7.5,11.8-12.2,19.9-13.2c-1.6,3.7-2.5,7.8-2.5,12.1v11.4
                l-9.7,5.6c-3.7,2.1-6.8,4.9-9.2,8.1C3.1,48.4,3.3,39.6,7.7,32z M7.7,88c-4.4-7.6-4.6-16.4-1.4-23.9c2.4,3.2,5.5,6,9.2,8.1l9.7,5.6
                v11.4c0,4.3,0.9,8.4,2.5,12.1C19.5,100.2,12,95.5,7.7,88z M56,115.9c-8.7,0-16.5-4.2-21.3-10.6c4-0.5,8-1.8,11.7-3.9l9.8-5.7
                l9.7,5.6c3.7,2.1,7.6,3.4,11.6,3.9C72.6,111.7,64.8,115.9,56,115.9z M104.5,87.9c-4.4,7.6-11.9,12.2-20,13.2
                c1.6-3.7,2.4-7.7,2.4-12V77.9l9.8-5.7c3.7-2.1,6.8-4.9,9.2-8.2C109.1,71.6,108.9,80.4,104.5,87.9z"/>
              <path class="st0" d="M56.1,41.4c0,10.2-8.3,18.5-18.5,18.5c10.2,0,18.5,8.3,18.5,18.5c0-10.2,8.3-18.5,18.5-18.5
                C64.4,59.9,56.1,51.7,56.1,41.4z"/>
            </g>
          </svg>
        </div>
        <div id="loadingBarTrack"></div>
        <div id="loadingBarFill"></div>
      </div>
    </div>
</template>

<script>
import gsap from 'gsap'
export default{
  name: "loader",
  mounted(){
    this.animate()
  },
  methods: {
    animate(){
      /**
      * Animation
      */
      var loadingTl = gsap.timeline()

      loadingTl.to('#loading-logo', {
        width: "40%",
        opacity: 1,
        duration: 0.8,
        transformOrigin: "left center",
        ease: 'Power3.inOut',
        delay: 0.3,
      }, '<')
      loadingTl.to('#loadingBarFill', {
        clipPath:"inset(0% 0% 0% 0%)",
        duration: 1,
        transformOrigin: "left center",
        ease: 'Power3.inOut',
        delay: 0.2,
      }, '<')
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper{
    clip-path: circle(100% at 50% 50%);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    z-index: 100;
  }
  #loading {
    z-index: 100;
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  #loading-logo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 30%;
    height: 100%;
    opacity: 0;

    #loading-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 100%;
      // transform: scale(0);
    }
  }
  .st0{
    fill: white;
  }

  #loading-circle2,
  #loading-circle3 {
    position: absolute;
    top: 50%; right: 50%;
    border: 5px #FFFFFF solid;
    border-radius: 200px;
    width: 40%;
    height: 100%;
    opacity: 0;
  }

  #loading-circle2 {
    transform: translate(50%,-50%);
  }

  #loading-circle3 {
    transform: translate(50%,-50%);
  }
  #loadingBarTrack,
  #loadingBarFill{
    position: absolute;
    height: 100%;
  }
  #loadingBarTrack{
    width: 100%;
    border-bottom: 3px solid #333333;

  }
  #loadingBarFill{
    width: 100%;
    clip-path: inset(0 100% 0 0);
    border-top: none;
    border-bottom: 3px solid;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-image-slice: 1;
    -moz-border-image-source: linear-gradient(90deg, #9d9d9d 50.78%, #83F8FF 58.91%, #FFEB85 66.19%, #FFC9F0 74.57%, #B3FABA 82.22%, #798EFE 90.71%, #9d9d9d 98.82%);
    -webkit-border-image-source: linear-gradient(90deg, #9d9d9d 50.78%, #83F8FF 58.91%, #FFEB85 66.19%, #FFC9F0 74.57%, #B3FABA 82.22%, #798EFE 90.71%, #9d9d9d 98.82%);
    border-image-source: linear-gradient(90deg, #9d9d9d 50.78%, #83F8FF 58.91%, #FFEB85 66.19%, #FFC9F0 74.57%, #B3FABA 82.22%, #798EFE 90.71%, #9d9d9d 98.82%);

  }
</style>